<template>
  <PrintSettlement ref="printSettlement"></PrintSettlement>
</template>
<script>
import PrintSettlement from "../../components/PrintSettlement.vue";
export default {
  components: {
    PrintSettlement,
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    getList() {
      this.list = [];
      var searchObj = {};

      if (this.id) {
        searchObj.id = this.id;
      }

      this.$api
        .getAPI({
          _action: "/user-settle-trades-list",
          _body: searchObj,
        })
        .then((res) => {
          if (res.list.length <= 0) {
            alert("No Data available");
            return;
          } else {
            var tempList = [];
            for (var i in res.list) {
              res.list[i].datatype = "DATA";
              var k = -1;
              for (var j in tempList) {
                if (tempList[j].trading_symbol.toUpperCase() == res.list[i].trading_symbol.toUpperCase()) {
                  k = j;
                }
              }

              var netamount = res.list[i].net_price * res.list[i].quantity;
              if (res.list[i].order_action == "BUY") {
                netamount = -netamount;
              }
              if (k == -1) {
                var temp = {
                  trading_symbol: res.list[i].trading_symbol,
                  list: [res.list[i]],
                  bq:
                    res.list[i].order_action == "BUY"
                      ? res.list[i].quantity
                      : 0,
                  sq:
                    res.list[i].order_action == "SELL"
                      ? res.list[i].quantity
                      : 0,
                  price: res.list[i].price,
                  net_price: res.list[i].net_price,
                  netamount: netamount,
                };
                tempList.push(temp);
              } else {
                tempList[k].bq +=
                  res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                tempList[k].sq +=
                  res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                tempList[k].price += res.list[i].price;
                tempList[k].net_price += res.list[i].net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res.list[i]);
              }
            }

            for (var m in tempList) {
              var obj = {
                datatype: "Total",
                title: "Script Wise total:",
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: tempList[m].net_price,
                netamount: tempList[m].netamount,
              };

              tempList[m].list.push(obj);
            }

            this.list = tempList;

            var name = (res.user_detail.name ? this.$helperService.getUpperCase(res.user_detail.name) : "") +
              (res.user_detail.code
                ? "(" + this.$helperService.getUpperCase(res.user_detail.code) + ")"
                : "")

            this.$refs.printSettlement.setDataAndPrint(this.list, name, res.week_Detail);
          }
        })
        .catch(() => { });
    },
  },
};
</script>
