<template>
  <div v-show="false">
    <div id="printablePrintSummary">
      <page>
        <div class="col-md-12 bg-light border-page">
          <div v-for="(report, index1) in list" :key="index1">
            <div class="row col-12">
              <div style="text-align: left" class="col-6">
                <span
                  style="
                    background: ;
                    background: #17fffb;
                    padding: 3px 15px;
                    border-radius: 3px;
                    font-size: 12px !important;
                  ">
                  {{ report.name_code }}</span
                >
              </div>

              <div style="text-align: right" class="col-6">
                <span style="padding: 3px 15px; border-radius: 3px">{{
                  dateRange
                }}</span>
              </div>
            </div>

            <table
              id="table"
              style="margin-top: 16px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Party Name</th>
                  <th class="head">MTM Amt</th>
                  <th class="head">Brok Amount</th>
                  <th class="head">Total Amt</th>
                  <th class="head">Br Brok</th>
                  <th class="head">Share %</th>
                  <th class="head">Br Share</th>
                  <th class="head">Net Amt</th>
                  <th class="head">INR Rate</th>
                  <th class="head">INR Amt</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in report.list"
                  :key="index"
                  style="cursor: pointer">
                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600;'
                    "
                    style="text-align: left; font-size: 10px !important">
                    {{ data.name }}
                  </td>
                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.amount < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.amount.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600;'
                    "
                    style="
                      text-align: right;
                      color: red;
                      font-size: 10px !important;
                    ">
                    {{ data.brokerage > 0 ? "-" : "" }}
                    {{
                      $helperService.getThousandConverter(
                        data.brokerage.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.net_amount < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.net_amount.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600; '
                    "
                    style="
                      text-align: right;
                      color: blue;
                      font-size: 10px !important;
                    ">
                    {{
                      $helperService.getThousandConverter(
                        data.brBrokerage.toFixed(2)
                      )
                    }}
                  </td>
                  <td
                    class="body"
                    :style="
                      data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3'
                        : 'font-weight: 600; '
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{ data.type == 'total' ? '' : data.sharing }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.shareAmt.toFixed(2)
                      )
                    }}
                  </td>

                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.netAmt < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.netAmt.toFixed(2)
                      )
                    }}
                  </td>
                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') 
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                        (data.netAmt > 0 ? data.profit : data.loss)
                    }}
                  </td>
                  <td
                    class="body"
                    :style="
                      (data.type == 'total'
                        ? 'font-weight: 700;  background: #ffffb3; '
                        : 'font-weight: 600; ') +
                      (data.netAmt < 0 ? 'color: red' : 'color: blue')
                    "
                    style="text-align: right; font-size: 10px !important">
                    {{
                      $helperService.getThousandConverter(
                        data.netAmtINR.toFixed(2)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </page>
    </div>
  </div>
  <ErrorComponent @retry="getList(0)" ref="errorComponent" />
</template>
<script>
import html2pdf from "html2pdf.js";
import ErrorComponent from "@/components/ErrorComponent";

export default {
  name: "PrintSummaryReport",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      list: [],
      name: "",
      week: "",
      gross_mtm: 0,
      brokerage: 0,
      net_mtm: 0,
    };
  },
  computed: {},
  mounted() {
 
  },
  methods: {
    setDataAndPrint(list) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = list;
      // this.gross_mtm = gross_mtm.toFixed(2);
      // this.brokerage = brokerage.toFixed(2);
      // this.net_mtm = net_mtm.toFixed(2);
      html2pdf(document.getElementById("printablePrintSummary"), {
        margin: [15, 10, 15, 10],
        image: { type: "jpeg", quality: 1 },
        filename: "Summary-Branch-report.pdf",
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: {
                        mode: ['avoid-all', 'css', 'legacy']
                    }
      });
      this.$refs.errorComponent.updateFormLoader(false);
    },
  },
};
</script>
