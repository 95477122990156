<template>
  <div>
    <div class="container form-signin3">
      <el-tabs
        v-if="userRole == 'SUPER_MASTER' || userRole == 'MANAGER'"
        v-model="activeName"
        style="max-width: 300px"
        class="col-3 demo-tabs"
        @tab-change="handleClick">
        <el-tab-pane label="Customer Ledger" name="first" id="first-tab">
        </el-tab-pane>
        <el-tab-pane
          label="Master Ledger"
          name="second"
          id="second-tab"></el-tab-pane>
      </el-tabs>
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <form class="form-inline transparent-form p10 border-page mt-2">
            <div class="col-lg-12 row">
              <div class="col-lg-2 col-sm-3 mt-1">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="Search"
                  v-model="keyword"
                  v-on:keyup="getList(0)" />
              </div>
              <div
                class="col-lg-2 col-sm-3 mt-1"
                v-if="role == 'SUPER_MASTER' || role == 'MASTER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="SubBroker"
                  v-model="broker_id"
                  :options="broker_list"
                  searchable="true" />
              </div>
              <div
                class="col-lg-2 col-sm-3 mt-1"
                v-if="role == 'SUPER_MASTER' || role == 'MANAGER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="Master"
                  v-model="master_id"
                  :options="master_list"
                  searchable="true" />
              </div>
              <div class="col-lg-6 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(0)"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="getListAll()"
                  class="btn btn-warning mb-2">
                  Get All
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page text-center">
          <table
            id="table"
            style="
              margin-top: 16px;
              width: 50%;
              margin-left: auto;
              margin-right: auto;
            "
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Sr.No</th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('name', 'id-name')">
                  Name
                </th>
                <th
                  class="head sorting"
                  style="width: 50%"
                  id="id-balance"
                  v-on:click="sorting('balance', 'id-balance')">
                  Balance
                </th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr
                v-for="(data, index) in list"
                :key="index"
                style="cursor: pointer">
                <td
                  style="font-size: 13px !important; font-weight: 600"
                  class="body"
                  v-on:click="
                    openWindow('/user-settlement/' + data.name + '/' + data.id)
                  ">
                  {{ getSrNo(index) }}
                </td>

                <td
                  style="
                    text-align: left;
                    font-size: 11px !important;
                    font-weight: 600;
                  "
                  class="body"
                  v-on:click="
                    openWindow('/user-settlement/' + data.name + '/' + data.id)
                  ">
                  {{ data.name }}
                </td>
                <td
                  style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  "
                  class="body"
                  :style="
                    data.balance < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  "
                  v-on:click="
                    openWindow('/user-settlement/' + data.name + '/' + data.id)
                  ">
                  {{
                    data.balance
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(data.balance)
                        ) +  getINRFormatValue(data.balance_inr)
                      : 0
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-10 mt-1 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
export default {
  components: {
    ErrorComponent,
    Field,
    Multiselect,
    DeleteModal,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      activeName: "first",
      role: this.$store.getters.getUser?.role,
      type: "Default",
      list: [],
      master_list: [],
      broker_list: [],
      fromDate: "",
      toDate: "",
    };
  },
  mounted() {
    this.checkAccess();
    this.getList(0);
    this.getBrokerList();
    this.getMasterList();
  },
  computed: {
      userRole() {
        return this.$store.getters.getUser?.role;
      },
    },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_ledgerReport == 0 && this.role != "ADMIN") {
        this.$router.push("/access-denied");
      }
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";

            // if (this.details.user_id == this.master_list[i].id) {
            //   this.user_detail = this.master_list[i];
            // }
          }
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
          for (var i in this.broker_list) {
            this.broker_list[i].new_name =
              this.broker_list[i].name + "(" + this.broker_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    handleClick() {
      if (this.activeName == "second") {
        this.$router.push("/master-ledger-report");
      }
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }

      if (this.type) {
        searchObj.type = this.type;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      searchObj.role = "USER";
      searchObj.limit = 20;
      searchObj.offset = page;

      searchObj.sort_by = "name";
      searchObj.order_by = "asc";
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].name =
              (res.list[i].name
                ? this.$helperService.getUpperCase(res.list[i].name)
                : "") +
              (res.list[i].code
                ? "(" + this.$helperService.getUpperCase(res.list[i].code) + ")"
                : "");
          }

          this.list = res.list;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 20);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    getListAll() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$refs.listPagination.setTotalCount(0);
      this.list = [];
      this.loader = false;
      var searchObj = {};
      searchObj.role = "USER";
      searchObj.limit = 1000;
      searchObj.offset = 0;
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      searchObj.sort_by = "name";
      searchObj.order_by = "asc";
      this.$api
        .getAPI({
          _action: "/account-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].name =
              (res.list[i].name
                ? this.$helperService.getUpperCase(res.list[i].name)
                : "") +
              (res.list[i].code
                ? "(" + this.$helperService.getUpperCase(res.list[i].code) + ")"
                : "");
          }

          this.list = res.list;
          this.$refs.errorComponent.setData(res, "No Data available");
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.setData(0, undefined, "");
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    sorting(sortBy, id, type) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      if (type) {
        var obj = this.$helperService.sorting(sortBy, id);
        this.getList(1, obj.order_by, obj.sort_by);
      } else {
        var $this = this;
        setTimeout(() => {
          $this.list = $this.$helperService.localSorting(
            $this.list,
            sortBy,
            id
          );
          $this.$refs.errorComponent.updateFormLoader(false);
          $this.loader = false;
        }, 1000);
      }
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 20 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Broker",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/account/" + detail.id);
    },
    openWindow(url) {
      window.open(url, "traget");
    },
    getINRFormatValue(value) {
      return ' ( ₹ ' + this.$helperService.getThousandConverter(
        this.$helperService.getFormattedPrice(value) 
                        )+ ' ) ';
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
