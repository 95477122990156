<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h6
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600">
            ORDER BOOK
          </h6>
          <form
            class="form-inline transparent-form border-page m-t-2 order-form-content">
            <div class="col-lg-12 row">
              <div class="col-12 col-sm-6 col-lg-2 col-xl-auto handle-radio">
                <div class="custom-control custom-radio mb-1">
                  <Field
                    v-model="order_type"
                    type="radio"
                    value="PENDING"
                    id="gross_partnership"
                    name="net_gross_partner"
                    v-on:change="getList()"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="font-size: 13px; padding-top: 5px"
                    for="gross_partnership"
                    class="custom-control-label mt-1 order-radio-lable"
                    >Pending Orders</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <Field
                    v-model="order_type"
                    type="radio"
                    value="EXECUTED"
                    id="gross_partnership2"
                    v-on:change="getList()"
                    name="net_gross_partner"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="font-size: 13px; padding-top: 5px; margin-left: 14px"
                    for="gross_partnership2"
                    class="custom-control-label mt-1 order-radio-lable"
                    >Completed Orders</label
                  >
                </div>
                <span class="text-error"></span>
              </div>
              <div class="col-lg-7 col-sm-12 row">
                <div class="col-lg-3 col-sm-12 mt-1" v-if="role != 'USER'">
                  <Multiselect
                    mode="single"
                    trackBy="new_name"
                    label="new_name"
                    valueProp="id"
                    class="register-select"
                    placeholder=" Client"
                    v-model="user_id"
                    :options="client_list"
                    searchable="true" />
                </div>
                <div class="col-lg-3 col-sm-12 mt-1">
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="value"
                    class="register-select"
                    placeholder=" Exchange"
                    v-model="exchange"
                    :options="exchanges"
                    searchable="true" />
                </div>
                <div class="col-lg-3 mt-1">
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="name"
                    class="register-select"
                    placeholder="Script"
                    v-model="name"
                    :options="script_list"
                    searchable="true" />
                </div>
                <div class="col-lg-3 mt-1">
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="value"
                    class="register-select"
                    placeholder="Buy/Sell"
                    v-model="trade_type"
                    :options="tradeType"
                    searchable="true" />
                </div>
              </div>

              <div class="col-lg-3 ml-4 text-right order-button-div">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList()"
                  class="btn btn-warning mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
                <downloadexcel
                  v-if="this.$store.getters.getAccess?.is_orderExport"
                  class="btn btn-primary mb-2 ml-2"
                  :fetch="fetchData"
                  :fields="json_fields"
                  name="Order-Book.xls"
                  :before-generate="startDownload"
                  :before-finish="finishDownload">
                  <span class="align-items-center d-flex">Export</span>
                </downloadexcel>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="order-table-content row"
        style="padding-left: 66px; padding-right: 36px; margin-top: 5px">
        <div class="col-md-12 border-page row">
          <div class="row col-md-12 order-table-searchbar">
            <div
              class="col-lg-2 col-md-4 col-sm-4 mb-1"
              style="
                font-weight: bold;
                margin-top: 10px;
                text-align: initial;
                font-size: 13px;
              ">
              {{
                order_type == "PENDING"
                  ? "Pending"
                  : order_type == "EXECUTED"
                  ? "Completed"
                  : ""
              }}
              Order : {{ count }}
            </div>
            <div
              class="col-lg-3 col-md-4 col-sm-4 mb-1"
              v-show="order_type == 'EXECUTED'"></div>
            <div
              class="col-lg-7 col-md-4 col-sm-4 mb-1"
              v-show="order_type == 'PENDING'"></div>
            <div
              class="col-lg-2 col-sm-3 mt-1"
              v-show="order_type == 'EXECUTED'">
              <datepicker
                v-model="fromDate"
                class="form-control"
                typeable="true"
                inputFormat="dd-MM-yyyy"
                placeholder="From Date"
                @selected="getList()"
                style="width: 100% !important" />
            </div>
            <div
              class="col-lg-2 col-sm-3 mt-1"
              v-show="order_type == 'EXECUTED'">
              <datepicker
                v-model="toDate"
                class="form-control"
                inputFormat="dd-MM-yyyy"
                placeholder="To Date"
                typeable="true"
                @selected="getList()"
                style="width: 100% !important" />
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 mb-1">
              <Field
                type="text"
                class="form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword"
                v-on:keyup="getList()" />
            </div>

            <div
              class="col-lg-1 mt-2 text-right"
              v-if=" order_type == 'PENDING' &&  this.$store.getters.getAccess?.is_orderDelete">
              <button
                v-on:click="deleteSelecOne()"
                class="delete-btn mr-2 cursor-pointer"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button">
                DELETE
              </button>
            </div>
          </div>

          <div class="col-md-12 bg-light sticky-table-header">
            <table
              id="table"
              style="margin-top: 16px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th v-if=" order_type == 'PENDING'" class="head" style="width: 50px !important">
                    <div class="switchToggleRead" style="text-align: start">
                      <input
                        type="checkbox"
                        v-model="isChecked"
                        :true-value="true"
                        :false-value="false"
                        v-on:change="checkall(isChecked)" />
                      <!-- Is Delete -->
                    </div>
                  </th>
                  <th
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderExecute
                    "
                    class="head sorting"
                    id="id-name"
                    v-on:click="sorting('id', 'id-name')">
                    Execute
                  </th>
                  <th
                    class="head sorting"
                    id="id-updated_at"
                    v-on:click="sorting('updated_at', 'id-updated_at')">
                    O-T
                  </th>
                  <th
                    v-if="order_type == 'EXECUTED'"
                    class="head sorting"
                    id="id-created_at"
                    v-on:click="sorting('created_at', 'id-created_at')">
                    T-T
                  </th>
                  <th
                    class="head sorting"
                    id="id-name"
                    v-on:click="sorting('id', 'id-name')">
                    Client
                  </th>
                  <th
                    class="head sorting"
                    id="id-trading_symbol"
                    v-on:click="sorting('trading_symbol', 'id-trading_symbol')">
                    Symbol
                  </th>
                  <th
                    class="head sorting"
                    id="id-order_action"
                    v-on:click="sorting('order_action', 'id-order_action')">
                    Type
                  </th>

                  <th
                    class="head sorting"
                    id="id-lot"
                    v-on:click="sorting('lot', 'id-lot')">
                    Lot
                  </th>
                  <th
                    class="head sorting"
                    id="id-quantity"
                    v-on:click="sorting('quantity', 'id-quantity')">
                    Qty
                  </th>

                  <th
                    class="head sorting"
                    id="id-price"
                    v-on:click="sorting('price', 'id-price')">
                    Order Price
                  </th>
                  <th
                    class="head sorting"
                    id="id-trade_status"
                    v-on:click="sorting('trade_status', 'id-trade_status')">
                    Status
                  </th>
                  <th
                    class="head sorting"
                    id="id-name"
                    v-on:click="sorting('id', 'id-name')">
                    User
                  </th>
                  <th
                    class="head sorting"
                    id="id-ip"
                    v-on:click="sorting('ip', 'id-ip')">
                    IP
                  </th>
                  <th
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderEdit
                    "
                    class="head"
                    id="id-name">
                    Modify
                  </th>
                  <th
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderDelete
                    "
                    class="head"
                    id="id-name">
                    Cancel
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loader">
                <tr v-for="(data, index) in list" :key="index">
                  <td v-if=" order_type == 'PENDING'" class="body">
                    <div class="switchToggleRead" style="text-align: start">
                      <input
                        :id="'index_' + data.id"
                        type="checkbox"
                        v-model="data.isChecked"
                        v-on:change="checkHandlling(data.isChecked)"
                        :true-value="true"
                        :false-value="false" />
                    </div>
                  </td>
                  <td
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderExecute
                    "
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.trade_status == 'PENDING'"
                      class="fa fa-play"
                      v-on:click="executeOrder(data.id, index)"
                      style="cursor: pointer"
                      aria-hidden="true"></i>
                  </td>

                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      order_type == "PENDING"
                        ? (data.updated_at
                          ? $helperService.getDateTime(data.updated_at)
                        : $helperService.getDateTime(data.created_at)
                        ): $helperService.getDateTime(data.created_at)
                    }}
                  </td>
                  <td
                    v-if="order_type == 'EXECUTED'"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.trade_date
                        ? $helperService.getDateTime(data.trade_date)
                        : ""
                    }}
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.user
                        ? $helperService.getUpperCase(
                            data.user?.name + "-" + data.user?.code
                          )
                        : ""
                    }}
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == false">
                    {{ data.lot }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby">
                      <Field
                        type="text"
                        class="form-control"
                        name="Lot"
                        style="width: 100px; height: 25px !important"
                        v-model="data.lot"
                        v-on:keyup="
                          updateQuantity(
                            data.lot,
                            data.script.custum_script.lot_size,
                            index
                          )
                        "
                        placeholder="Lot"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == false">
                    {{ data.quantity }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby">
                      <Field
                        type="text"
                        class="form-control"
                        name="Qty"
                        style="width: 100px; height: 25px !important"
                        :style="
                          data.exchange == 'MCX' ? 'cursor: no-drop;' : ''
                        "
                        v-model="data.quantity"
                        placeholder="Qty"
                        :disabled="data.exchange == 'MCX'"
                        v-on:keyup="
                          updateLotNew(
                            data.quantity,
                            data.script.custum_script.lot_size,
                            index
                          )
                        "
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == false">
                    {{ data.price }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby">
                      <Field
                        type="text"
                        style="width: 100px; height: 25px !important"
                        class="form-control"
                        name="Price"
                        placeholder="Price"
                        v-model="data.price"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>

                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.trade_status }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    style="text-align: left">
                    {{
                      data.athority
                        ? $helperService.getUpperCase(data.athority?.code)
                        : ""
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.ip }}
                  </td>
                  <td
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderEdit
                    "
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="this.$store.getters.getAccess?.is_orderEdit"
                      v-on:click="editRow(index)"
                      class="fa fa-pencil-square-o mr-2"
                      aria-hidden="true"
                      style="
                        cursor: pointer;
                        color: rgb(29, 68, 91);
                        font-size: 21px;
                      "></i>
                    <i
                      v-if="data.isEdit == true"
                      v-on:click="updateRow(index)"
                      class="fa fa-check mr-2"
                      aria-hidden="true"
                      style="
                        cursor: pointer;
                        color: green;
                        font-size: 21px;
                      "></i>
                    <i
                      v-if="data.isEdit == true"
                      v-on:click="closeIsEdit()"
                      class="fa fa-times mr-2"
                      aria-hidden="true"
                      style="cursor: pointer; color: red; font-size: 21px"></i>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="
                      order_type == 'PENDING' &&
                      this.$store.getters.getAccess?.is_orderDelete
                    ">
                    <!-- v-if="data.trade_status == 'PENDING'" -->
                    <i
                      v-on:click="showDeletePopup(data)"
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style="cursor: pointer; color: red; font-size: 21px"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getList()" ref="errorComponent" />
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import DeleteModal from "@/components/DeleteModal";
import Datepicker from "vue3-datepicker";
import moment from "moment";
import swal from "sweetalert2";
import downloadexcel from "vue-json-excel3";

export default {
  components: {
    Field,
    DeleteModal,
    Datepicker,
    Multiselect,
    ErrorComponent,
    downloadexcel,
  },
  name: "Profile",
  data() {
    return {
      role: this.$store.getters.getUser?.role,
      loader: true,
      isChecked: false,
      list: [],
      exchange: "COMEX",
      count: 0,
      keyword: "",
      user_id: "",
      trade_type: "",
      name: "",
      order_type: "PENDING",
      fromDate: new Date(),
      toDate: new Date(),
      type: "",
      json_fields: {
        "O-Time": "time",
        Segment: "exchange",
        Client: "client",
        "Expire Date": "expiry",
        "Script Name": "trading_symbol",
        "Order Type": "order_type",
        Lot: "lot",
        Quantity: "quantity",
        Price: "price",
        "Trade Status": "trade_status",
        User: "user_name",
        Ip: "ip",
      },
      excel_data: [],
      client_list: [],
      script_list: [],
      exchanges: [
      
         {
          name: "COMEX",
          value: "COMEX",
        },
      ],
      tradeType: [
        {
          name: "BUY",
          value: "BUY",
        },
        {
          name: "SELL",
          value: "SELL",
        },
      ],
    };
  },
  mounted() {
    this.order_type = this.$route.query.type ? "EXECUTED" : "PENDING";
    this.getList();
    this.getClientList();
    this.getScriptList("COMEX", "FIRST");
  },
  methods: {
    async fetchData() {
      return this.excel_data;
    },

    updateLotNew(quantity, lot_size, index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].lot = this.$helperService.getFormattedPrice(
            quantity / lot_size
          );
        }
      }
    },
    getScriptList(exchange, type) {
      var searchObj = {};
      if (this.exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          if (!type) {
            this.filterData();
          }
        })
        .catch(() => {});
    },
    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-trade",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.isChecked = false;
            }
            this.getList();
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    updateQuantity(lot, lot_size, index) {
      var qty = lot * lot_size;
      for (var i in this.list) {
        if (i == index) {
          this.list[i].quantity = qty;
        }
      }
    },
    editRow(index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isEdit = true;
        } else {
          this.list[i].isEdit = false;
        }
      }
    },
    closeIsEdit() {
      for (var i in this.list) {
        this.list[i].isEdit = false;
      }
    },
    updateRow(index) {
      for (var i in this.list) {
        if (index == i) {
          this.list[i].isEdit = false;
          this.list[i].lot = this.$helperService.getFormattedPrice(
            this.list[i].lot
          );
          this.list[i].price = this.$helperService.getFormattedPriceCmx(
            this.list[i].price
          );
          this.list[i].quantity = this.$helperService.getFormattedPrice(
            this.list[i].quantity
          );
          var obj = this.list[i];
        }
      }
      this.$api
        .postAPI({
          _action: "/update-trade",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getList();
        })
        .catch(() => {
          this.getList();
        });
    },
    executeOrder(id, index) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to execute trade",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var obj = {};
            obj.id = id;
            this.$api
              .postAPI({
                _action: "/update-trade-status",
                _body: obj,
              })
              .then((res) => {
                var text =
                  "Trade has been successfully executed. " +
                  res.data.name +
                  "   " +
                  this.$helperService.getCustomDateYear(res.data.expiry) +
                  "   " +
                  res.data.order_action +
                  " " +
                  res.data.quantity +
                  "@" +
                  Number(res.data.price).toFixed(2) +
                  "    For " +
                  this.$helperService.getUpperCase(res.data.user_name) +
                  "(" +
                  res.data.code +
                  ")";
                if (res.data.order_action == "SELL") {
                  this.$notify({
                    type: "error",
                    text: text,
                  });
                }
                if (res.data.order_action == "BUY") {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: text,
                  });
                }
                this.list.splice(index, 1);
              })
              .catch(() => {});
          } else {
            // this.getList(0);
          }
        });
    },
    startDownload() {
      console.log("d");
    },
    finishDownload() {
      console.log("d");
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    getList(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      this.excel_data = [];
      var searchObj = {};
      searchObj.order_by = "desc";
      searchObj.sort_by = "created_at";

      if (this.order_type == "EXECUTED") {
        if (this.fromDate) {
          searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
        }

        if (this.toDate) {
          searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
        }
      }

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.order_type) {
        searchObj.trade_type = this.order_type;
      }
      if (this.order_type) {
        searchObj.trade_status = this.order_type;
      }
      if (this.order_type == "EXECUTED") {
        searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      }
      if (this.trade_type) {
        searchObj.trade_action_type = this.trade_type;
      }
      if (this.name) {
        searchObj.name = this.name;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }

      if (this.order_type == "PENDING") {
        searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      }
      this.$api
        .getAPI({
          _action: this.order_type == "PENDING" ?  '/pending-order' : "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          this.count = this.list.length;
          for (var i in this.list) {
            this.list[i].isEdit = false;
            this.list[i].lot = this.$helperService.getFormattedPrice(
              this.list[i].lot
            );
            this.list[i].price = this.$helperService.getFormattedPriceCmx(
              this.list[i].price
            );
            this.list[i].quantity = this.$helperService.getFormattedPrice(
              this.list[i].quantity
            );
          }
          this.excel_data = [];
          if (res.length > 0) {
            for (var j in res) {
              var obj = {};
              obj.exchange = res[j].exchange == "MCX" ? res[j].exchange : "NSE";
              obj.time = this.$helperService.getTime(res[j].created_at);
              obj.client = res[j].user
                ? this.$helperService.getUpperCase(
                    res[j].user.name + "-" + res[j].user.code
                  )
                : "";
              obj.expiry = res[j].script ? res[j].script.expiry : "";
              obj.trading_symbol = res[j].trading_symbol;
              obj.order_type = res[j].order_action;
              obj.lot = res[j].lot;
              obj.quantity = res[j].quantity;
              obj.price = res[j].price;
              obj.trade_status = res[j].trade_status;
              obj.user_name = res[j].user
                ? this.$helperService.getUpperCase(res[j].user.name)
                : "";
              obj.ip = res[j].ip;
              this.excel_data.push(obj);
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.getList();
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Order",
        "Are you sure  want to delete order",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/trade/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
