<template>
  <div v-show="false">
    <div id="printablePrintConsolidated">
      <page>
        <div class="col-md-12 bg-light border-page">
          <div class="row col-12">
            <div style="text-align: left" class="col-6"></div>
            <div style="text-align: right" class="col-6">
              <span style="padding: 3px 15px; border-radius: 3px">{{
                dateRange
              }}</span>
            </div>
          </div>

          <table
           v-if="consolidateList.length > 1"
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Party Name</th>
                <th class="head">MTM Amt</th>
                <th class="head">Brok Amount</th>
                <th class="head">Total Amt</th>
                <th class="head">Br Brok</th>
                <th class="head">Share %</th>
                <th class="head">Br Share</th>
                <th class="head">Net Amt</th>
                <th class="head">INR Amt</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in consolidateList"
                :key="index"
                style="cursor: pointer">
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="text-align: left; font-size: 13px !important">
                  {{ data.name }}
                </td>
                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.amount.toFixed(2))
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="
                    text-align: right;
                    color: red;
                    font-size: 13px !important;
                  ">
                  {{ data.brokerage > 0 ? "-" : "" }}
                  {{
                    $helperService.getThousandConverter(
                      data.brokerage.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.net_amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.net_amount.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="
                    text-align: right;
                    color: blue;
                    font-size: 13px !important;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.brBrokerage.toFixed(2)
                    )
                  }}
                </td>
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{ data.sharing }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.shareAmt.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.netAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.netAmt.toFixed(2))
                  }}
                </td>
                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.netAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.netAmtINR.toFixed(2))
                  }}
                </td>
              </tr>
            </tbody>
          </table>


          

          <table
            id="brokTable"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">Party Name</th>
                <th class="head">MTM Amt</th>
                <th class="head">Brok Amount</th>
                <th class="head">Total Amt</th>
                <th class="head">Br Brok</th>
                <th class="head">Share %</th>
                <th class="head">Br Share</th>
                <th class="head">Net Amt</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in consolidateBrokerList"
                :key="index"
                style="cursor: pointer">
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="text-align: left; font-size: 13px !important">
                  {{ data.name }}
                </td>
                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.amount.toFixed(2))
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600;'
                  "
                  style="
                    text-align: right;
                    color: red;
                    font-size: 13px !important;
                  ">
                  {{ data.brokerage > 0 ? "-" : "" }}
                  {{
                    $helperService.getThousandConverter(
                      data.brokerage.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.net_amount < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.net_amount.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="
                    text-align: right;
                    color: blue;
                    font-size: 13px !important;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.brBrokerage.toFixed(2)
                    )
                  }}
                </td>
                <td
                  class="body"
                  :style="
                    data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3'
                      : 'font-weight: 600; '
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{ data.sharing }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.shareAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(
                      data.shareAmt.toFixed(2)
                    )
                  }}
                </td>

                <td
                  class="body"
                  :style="
                    (data.type == 'total'
                      ? 'font-weight: 700;  background: #ffffb3; '
                      : 'font-weight: 600; ') +
                    (data.netAmt < 0 ? 'color: red' : 'color: blue')
                  "
                  style="text-align: right; font-size: 13px !important">
                  {{
                    $helperService.getThousandConverter(data.netAmt.toFixed(2))
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </page>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";

export default {
  name: "PrintSummaryReport",
  data() {
    return {
      consolidateList: [],
      consolidateBrokerList: [],
      name: "",
      week: "",
      gross_mtm: 0,
      brokerage: 0,
      net_mtm: 0,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setDataAndPrint(list, brokerList) {
      this.consolidateList = list;
      this.consolidateBrokerList = brokerList;
      html2pdf(document.getElementById("printablePrintConsolidated"), {
        margin: [15, 10, 15, 10],
        image: { type: "jpeg", quality: 1 },
        filename: "Consolidated-branch-report.pdf",
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: {
                        mode: ['avoid-all', 'css', 'legacy']
                    }
      });
    },
  },
};
</script>
